define("empress-blog-coffee-into-code-template/templates/components/post-card", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "qtRToQxw",
    "block": "[[[10,\"article\"],[14,0,\"rounded overflow-hidden shadow-lg mt-4\"],[12],[1,\"\\n\"],[6,[39,0],null,[[\"route\",\"model\"],[\"post\",[30,1,[\"id\"]]]],[[\"default\"],[[[[1,\"    \"],[10,0],[14,0,\"post-card-content p-2 border-b-1 border border-t-0 border-r-0 border-l-0 bg-gray-100\"],[12],[1,\"\\n      \"],[10,1],[14,0,\"text-orange-800 text-xs\"],[12],[1,[28,[35,1],[[30,1,[\"date\"]],\"M/D/YYYY\"],null]],[13],[1,\"\\n      \"],[10,\"h1\"],[14,0,\"text-2xl font-bold text-gray-800\"],[12],[1,[30,1,[\"title\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"p-2\"],[12],[1,\"\\n\"],[41,[30,1,[\"image\"]],[[[1,\"        \"],[10,\"img\"],[14,0,\"h-64 w-full object-cover mb-4\"],[15,\"src\",[29,[[30,1,[\"image\"]]]]],[15,\"alt\",[30,0,[\"altText\"]]],[12],[13],[1,\"\\n\"]],[]],null],[1,\"      \"],[10,2],[12],[1,\"\\n        \"],[1,[30,0,[\"contentSummary\"]]],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"]],[]]]]],[1,\"    \"],[10,0],[14,0,\"p-2\"],[12],[1,\"\\n      \"],[10,0],[14,0,\"my-4\"],[12],[1,\"\\n\"],[42,[28,[37,4],[[28,[37,4],[[30,1,[\"tags\"]]],null]],null],null,[[[6,[39,0],null,[[\"route\",\"model\"],[\"tag\",[30,2,[\"id\"]]]],[[\"default\"],[[[[1,\"          \"],[10,1],[14,0,\"text-gray-800 rounded-full bg-gray-300 py-2 px-4 mr-2 text-sm\"],[12],[1,\"#\"],[1,[30,2,[\"name\"]]],[13],[1,\"\\n\"]],[]]]]]],[2]],null],[1,\"      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@post\",\"tag\"],false,[\"link-to\",\"moment-format\",\"if\",\"each\",\"-track-array\"]]",
    "moduleName": "empress-blog-coffee-into-code-template/templates/components/post-card.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});