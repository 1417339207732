define("empress-blog-coffee-into-code-template/templates/components/navigation", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = (0, _templateFactory.createTemplateFactory)({
    "id": "ihM/U4HU",
    "block": "[[[10,\"ul\"],[14,0,\"flex\"],[14,\"role\",\"menu\"],[12],[1,\"\\n\"],[42,[28,[37,1],[[28,[37,1],[[30,0,[\"blog\",\"navigation\"]]],null]],null],null,[[[1,\"      \"],[10,\"li\"],[15,0,[29,[[52,[28,[37,3],[[30,1,[\"route\"]]],null],\"nav-current\"],\" mr-8 hover:no-underline\"]]],[14,\"role\",\"menuitem\"],[12],[1,\"\\n\"],[41,[30,1,[\"id\"]],[[[1,\"          \"],[6,[39,4],null,[[\"route\",\"model\"],[[30,1,[\"route\"]],[30,1,[\"id\"]]]],[[\"default\"],[[[[1,\" \"],[1,[30,1,[\"label\"]]],[1,\" \"]],[]]]]],[1,\"\\n\"]],[]],[[[41,[30,1,[\"route\"]],[[[1,\"          \"],[6,[39,4],null,[[\"route\"],[[30,1,[\"route\"]]]],[[\"default\"],[[[[1,\" \"],[1,[30,1,[\"label\"]]],[1,\" \"]],[]]]]],[1,\"\\n\"]],[]],[[[41,[30,1,[\"link\"]],[[[1,\"          \"],[10,3],[15,6,[29,[[30,1,[\"link\"]]]]],[12],[1,\" \"],[1,[30,1,[\"label\"]]],[1,\" \"],[13],[1,\"\\n        \"]],[]],null]],[]]]],[]]],[1,\"      \"],[13],[1,\"\\n\"]],[1]],null],[13],[1,\"\\n\"]],[\"navigation\"],false,[\"each\",\"-track-array\",\"if\",\"route-is\",\"link-to\"]]",
    "moduleName": "empress-blog-coffee-into-code-template/templates/components/navigation.hbs",
    "isStrictMode": false
  });

  _exports.default = _default;
});