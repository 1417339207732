define("ember-body-class/instance-initializers/body-class", ["exports", "@ember/object/evented", "@ember/object", "@ember/routing/route", "@ember/application", "ember-body-class/util/bodyClass", "ember-get-config"], function (_exports, _evented, _object, _route, _application, _bodyClass, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  /* eslint-disable ember/no-get, ember/no-observers */
  function initialize() {
    // Default to true when not set
    var _includeRouteName = true;

    if (_emberGetConfig.default['ember-body-class'] && _emberGetConfig.default['ember-body-class'].includeRouteName === false) {
      _includeRouteName = false;
    }

    _route.default.reopen({
      classNames: [],
      bodyClasses: null,
      init: function init() {
        this._super.apply(this, arguments);

        (0, _object.set)(this, 'bodyClasses', []);
      },
      _getRouteDepthClasses: function _getRouteDepthClasses() {
        var routeParts = this.get('routeName').split('.');
        var routeDepthClasses = routeParts.slice(0);
        var currentSelector = [];
        routeParts.forEach(function (part) {
          currentSelector.push(part);
          routeDepthClasses.push(currentSelector.join("-"));
        });
        return routeDepthClasses;
      },
      addClasses: (0, _evented.on)('activate', function () {
        this._setClassNamesOnBodyElement();
      }),
      _setClassNamesOnBodyElement: function _setClassNamesOnBodyElement() {
        var _this = this;

        var _getOwner$lookup = (0, _application.getOwner)(this).lookup('service:-document'),
            body = _getOwner$lookup.body;

        ['bodyClasses', 'classNames'].forEach(function (classes) {
          _this.get(classes).forEach(function (klass) {
            (0, _bodyClass.addClass)(body, klass);
          });
        });

        if (_includeRouteName) {
          this._getRouteDepthClasses().forEach(function (depthClass) {
            (0, _bodyClass.addClass)(body, depthClass);
          });
        }
      },
      updateClasses: (0, _object.observer)('bodyClasses.[]', 'classNames.[]', function () {
        var _this2 = this;

        var _getOwner$lookup2 = (0, _application.getOwner)(this).lookup('service:-document'),
            body = _getOwner$lookup2.body;

        ['bodyClasses', 'classNames'].forEach(function (classes) {
          _this2.get(classes).forEach(function (klass) {
            (0, _bodyClass.removeClass)(body, klass);
          });
        });

        this._setClassNamesOnBodyElement();
      }),
      removeClasses: (0, _evented.on)('deactivate', function () {
        var _this3 = this;

        // for some reason we're getting deactivate called too early and it's
        // removing the classes in fastboot only.
        if (typeof FastBoot !== 'undefined') {
          return;
        }

        var _getOwner$lookup3 = (0, _application.getOwner)(this).lookup('service:-document'),
            body = _getOwner$lookup3.body;

        ['bodyClasses', 'classNames'].forEach(function (classes) {
          _this3.get(classes).forEach(function (klass) {
            (0, _bodyClass.removeClass)(body, klass);
          });
        });

        if (_includeRouteName) {
          this._getRouteDepthClasses().forEach(function (depthClass) {
            (0, _bodyClass.removeClass)(body, depthClass);
          });
        }
      })
    });
  }

  var _default = {
    name: 'body-class',
    initialize: initialize
  };
  _exports.default = _default;
});